import { Avatar, Button, Card, Col, Row } from 'antd';
import { Icon } from 'antd-mobile';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import likeIcon from '../../../assets/images/like.svg';
import likeOutlineIcon from '../../../assets/images/icons/like-outline.svg';
import likeFillIcon from '../../../assets/images/icons/like-filled.svg';
import { bucket_url } from '../../../constants/constString';
import { upvoteComment, likeQuestion, studentBookmarkQuestion } from '../../../stateManager/reducers/qnaSlice';

import NotBookmarked from '../../../assets/images/icons/bookmark-outline.svg';
import Bookmarked from '../../../assets/images/icons/bookmark.svg';
import { Modal } from 'antd';

const PostComponent = (props) => {
    const { comment, isQuestion, updateQuestion  } = props;
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [fileToView, setFileToView] = React.useState(null);
    const userType = comment.userType;

    const dispatch = useDispatch();
    const upvotes = useSelector(state => state.qna.upvotes);

    const showPreview = (file, type) => {
        setFileToView({
            mediaUrl: bucket_url+ file,
            mediaType: type
          })
        setIsModalVisible(true);
    }
    const renderFilePreview = (file, type) => {
        if (type.startsWith('image/')) {
          return <div className="img-section" onClick={()=>showPreview(file, type)}><img src={bucket_url+ file} alt={file.name} className="image-item" /></div>;
        } else if (type ===  'application/pdf') {
          return (
            <div className='pdf-section'
            style={{
              cursor: 'pointer',
             
            }}
            onClick={() => {
              setFileToView({
                mediaUrl: bucket_url + file,
                mediaType: type,
              });
              setIsModalVisible(true);
            }}
          >
            {/* Instead of directly embedding <object> here, use a wrapper div */}
            <div
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              <object
                className="pdf"
                data={bucket_url + file}
                width="200"
                height="100"
                style={{
                  pointerEvents: 'none', // Disable pointer events for object tag to avoid click interference
                }}
              />
            </div>
          </div>)
        } else if (type.startsWith('audio/')) {
          return <div className="audio-section"> <audio controlsList="nodownload" controls src={bucket_url+ file} /></div>;
        }
        return null;
      };
    const getTile = () => {
        console.log('comment', userType);
        if (comment.userType === "Admin") {
            return (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-end', // This ensures all items in this flex container are vertically centered
                        marginBottom: '24px',
                        justifyContent: 'flex-start',
                    }}>
                        <div style={{
                            lineHeight: '10px', // Increased line height for better visual alignment
                            marginRight: '10px',
                            
                        }}>

                            <h3 className="user-name"><span style={{color: "#018CE8"}}>{`Teacher `}</span>
                            {/* <span style={{
                            color: "#475569",
                                fontStyle: 'underline',
                            }}>{`${comment.userId? comment.userId.firstName:'Guest'} ${comment.userId?comment.userId.lastName:'User'}`}</span> */}
                            </h3>
                            <h6 className="time">{new Date(comment.createdAt).toLocaleString()}</h6>
                        </div>
                        {/* <Avatar
                            style={{ 
                                backgroundColor: comment.userType === "Admin" ? "red" : "blue",
                            }} className="avatar"
                        >
                            {comment.userType === "Admin" ? "A" : "S"}
                        </Avatar> */}
                    </div>
            );
        } else {
            return ( <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: '24px',
                justifyContent: 'flex-end',
                textAlign:'right'

            }}>
                
                {/* <Avatar
                    style={{ 
                    backgroundColor: comment.userType === "Admin" ? "red" : "blue"
                    }}
                    className="avatar"
                >
                {comment.userType === "Admin" ? "A" : "S"}
                </Avatar> */}
                <div style={{
                    lineHeight: '10px',

                }}>
                    <h3 className="user-name">{comment?.userId?.name || 'Guest'}</h3>
                    <h6 className="time">{new Date(comment.createdAt).toLocaleString()}</h6>
                </div>
            </div>);
        }
    }

    const bookmarkQuestion = async (questionId) => {
      const student = JSON.parse(localStorage.getItem('student'));
      await dispatch(studentBookmarkQuestion(
        {studentId: student.id, questionId: questionId}));
        updateQuestion();
    }

    const upvote = async() => {
      if(isQuestion){
        const res = await dispatch(likeQuestion({questionId: comment._id}));
        updateQuestion();
        return;
      }
      else{
        await dispatch(upvoteComment({commentId: comment._id,
          questionId: comment.questionId
      }));
      }
        
    }

    const mediaView = () => {
        return <Modal open={isModalVisible} destroyOnClose 
       width={1000}
        footer={null}
        onCancel={() => {
            setFileToView(null);
            setIsModalVisible(false);
        }
        }
        >
            {
                 fileToView?.mediaType.startsWith('image/') ?
                 <img src={fileToView?.mediaUrl} alt="media" width="100%" height="100%"/>:
                <object class="pdf" data={fileToView?.mediaUrl}  style={{width: '100%', height: '100vh',}}> </object>
                
            }
           
        </Modal>
    }
    
  return (
    <>
        <Card className={`question-card ${userType}`}>
            <Row>
                {/* Bookmark icon aligned to the right */}
                {
                  isQuestion && <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    position: 'absolute',
                    left: '10px',
                  }}
                  className="bookmark-icon"
                  onClick={() => {
                    console.log('Bookmark clicked');
                    bookmarkQuestion(comment._id);
                  }
                  }
                >
                  <img src={comment.isBookmarked?Bookmarked:NotBookmarked} />
              </div>
                }
                
                <Col xs={{ span: 24 }} lg={{ span: 18 , offset: userType !== "Admin" && 6 }} className={`total-data ${userType}-data`}>
                 
                {getTile()}
                <p style={{
                    textAlign: comment.userType !== "Admin" ? "right" : "left",
                }} className="question-text">{comment.reply}</p>
                
                    {comment.media.length > 0 && (
                        
                            <div style={{ marginTop: '10px',
                                display: comment.userType === "Admin" ? "" : "",
                                flexDirection: '',
                                alignItems: comment.userType === "Admin" ? "flex-start" : "flex-end",
                            }}>
                                <Row gutter={[20,20]} style={{justifyContent:comment.userType === "Admin" ? "flex-start" : "flex-end"}} className='image-parent'> 
                                {comment.media.filter(item=> item.mediaType.startsWith('image/')).map((file, index) => (
                                     
                                        <Col xs={{ span: 24 }} lg={{ span:8 }} key={index}>{renderFilePreview(file.mediaUrl, file.mediaType)}
                                        </Col>
                                ))}
                                </Row>
                                <Row gutter={[20,20]} style={{justifyContent:comment.userType === "Admin" ? "flex-start" : "flex-end"}} className='pdf-parent'>
                                    {comment.media.filter(item=> item.mediaType.startsWith('application/pdf')).map((file, index) => (
                                        
                                        <Col xs={{ span: 24 }} lg={{ span:8 }} key={index}>{renderFilePreview(file.mediaUrl, file.mediaType)}
                                        </Col>
                                ))}
                                </Row>
                                <Row gutter={[20,20]} style={{justifyContent:comment.userType === "Admin" ? "flex-start" : "flex-end"}} className='audio-parent'>
                                {comment.media.filter(item=> item.mediaType.startsWith('audio/')).map((file, index) => (
                                        
                                        <Col xs={{ span: 24 }} lg={{ span:8 }} key={index}>{renderFilePreview(file.mediaUrl, file.mediaType)}
                                        </Col>
                                ))}
                                </Row>
                            </div>
                    )}
               {
                (
                    <div  className='upvote-section'>
                      {
                        isQuestion? <Button className='like-btn' type="text" onClick={()=>{
                          upvote();
                      }}>
                          {comment?.isLiked? 
                           <img src={likeFillIcon} alt="like" style={{
                              width: '20px',
                              height: '20px',
                          }}/> 
                          :
                          <img src={likeOutlineIcon} alt="like" style={{
                              width: '20px',
                              height: '20px',
                          }}/>
                      }
                      </Button>:
                      <Button className='like-btn' type="text" onClick={()=>{
                        upvote();
                    }}>
                        {upvotes.includes(comment._id) ? 
                         <img src={likeFillIcon} alt="like" style={{
                            width: '20px',
                            height: '20px',
                        }}/> 
                        :
                        <img src={likeOutlineIcon} alt="like" style={{
                            width: '20px',
                            height: '20px',
                        }}/>
                    }
                    </Button>
                      }
                        
                        <span>{isQuestion? comment.likes: comment.upvotes} Upvotes</span>
                    </div>
                )
               }
              </Col>
            </Row>
        </Card>
        {
            mediaView()
        }
    
    </>
  );
};

export default PostComponent;

import React from 'react';

import { Form, Input, Button, Select, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Layout } from 'antd';

import selectDropdownIcon from '../../../assets/images/icons/select-dropdown-icon.svg';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import  FileUploader  from './FileUploader';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import {
	patchFileRequest,
	signedUrl as signedUrlRequest, createQuestion, createComment
} from '../../../stateManager/reducers/qnaSlice';
import { set } from 'js-cookie';



const { Option } = Select;
const { TextArea } = Input;
const {  Content } = Layout;


const StudentQuestionForm = (props) => {
  const { questionId, reply } = props;
  console.log('reply', reply);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [form] = Form.useForm();
  const [files, setFiles] = React.useState([]);
  const history = useHistory();
  const [subjectByCourse, setSubjectByCourse] = React.useState(null);
  const [chapterBySubject, setChapterBySubject] = React.useState([]);
  const courses = JSON.parse(localStorage.getItem('courses'));
  const student = JSON.parse(localStorage.getItem('student'));
  const groupSubjects = useSelector(state => state.qna.groupSubjects);


  const dispatch = useDispatch();

  // const getSignedUrl = async (file) => {
  //   try {
  //     const signedUrlRes = await dispatch(signedUrlRequest(file.type));
  //     console.log('Signed URL response:', signedUrlRes);
  //     await uploadFile(file, signedUrlRes.data.signedUrl);
  //   } catch (error) {
  //     console.error('Error getting signed URL:', error);
  //   }
  // };

  const onFinish = async (values) => {
    const media = []
    for (const file of files) {
      const signedUrlRes = await dispatch(signedUrlRequest(file.type));
        try {
        const res =  await dispatch(patchFileRequest({signedUrl: signedUrlRes?.payload?.data?.signedUrl,file: file}));
        console.log('File upload response:', res);
        media.push({
          mediaType: file.type,
          mediaUrl: signedUrlRes?.payload?.data?.key,
        });
        
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }

    if(questionId){
      const data = {
        questionId: questionId,
        userId: student.id,
        userType:"Student", 
        reply: form.getFieldValue('details'), 
        media: media,
      };
      try {
        await dispatch(createComment(data));
        message.success('Reply submitted successfully!');
        form.resetFields();
        setFiles([]);
        setIsModalOpen(false);
      } catch (error) {
        console.error('Error creating reply:', error);
        message.error('Error submitting reply. Please try again later.');
        return;
      }
      return;
    }
    else{
      const data = {
        studentId: student.id,
        courseId: form.getFieldValue('course'),
        subjectId: form.getFieldValue('subject'),
        questionDescription: form.getFieldValue('details'),
        media: media,
        // tags: form.getFieldValue('tags'),
      };
  
      try {
        const res = await dispatch(createQuestion(data));
        console.log('Question created:', res.payload.data);
        history.push(`/question-details/${res.payload.data._id}`);
        message.success('Question submitted successfully!');
        form.resetFields();
        setFiles([]);
        setIsModalOpen(false);
      } catch (error) {
        console.error('Error creating question:', error);
        message.error('Error submitting question. Please try again later.');
        return;
      }
    }
    
  }
    const showModal = () => {
    setIsModalOpen(true);
  };

  const submit = async (values) => {

    await form.submit()
   
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    message.error('Please check the form for errors.');
  };

  React.useEffect(() => {
    console.log('Files:', files);
  }, [files]);

  return (
    <div >
      {
        questionId ? (
           reply && <Button type="outlined" danger onClick={showModal} style={{
            width: '100%',
            height: '50px',
            textAlign: 'left', 
          }}
          
          > Reply...
          </Button>
        ) :  <Button type="danger" className="ask-question-btn" onClick={showModal} >Ask a Question
       </Button>

      }
      
       <Modal
        // title={questionId ? 'Reply to Question' : 'Ask a Question'}
        open={isModalOpen}
        destroyOnClose={true}        
        footer={null}
        centered
        onCancel={()=>{
          form.resetFields();
          setFiles([]);
            setIsModalOpen(false);

        }}
        className="custom-modal"
        closeIcon={<div className="modal-close-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <path d="M24 8L8 24M8 8L24 24" stroke="#EE473F" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        </div>}
        >
          <Layout style={{
          height: 'auto',
        }} className="modal-layout">
          <Content className="modal-content">
            {
              questionId ? 
              <h4 className="modal-title">Reply</h4>
              : 
              <h4 className="modal-title">Create New Question</h4>
            }
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}>
          {/* write Select for course */}

            {
              questionId ? null : (<>
              <Form.Item
              name="course"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              rules={[{ required: true, message: 'Please select a course' }]}
            >
              <Select placeholder="Select a course" 
                onChange={(courseId) => {
                  const course = courses.find((course) => course._id === courseId);
                  const subjects = course?.subjects.filter((subject) => groupSubjects.includes(subject._id));
                  setSubjectByCourse(subjects);
                }
                }
                suffixIcon={<img src={selectDropdownIcon} alt='' />}
              >
                {courses?.map((course) => (
                  <Option key={course._id} value={course._id}>
                    {course.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {/* write Select for subject */}
            <Form.Item
              name="subject"
              rules={[{ required: true, message: 'Please select a subject' }]}
              onChange={(subjectId)=>{
                const subject = subjectByCourse.find((subject) => subject._id === subjectId);
                setChapterBySubject(subject?.chapters);
    
              }}
            >
              <Select placeholder="Select a subject" suffixIcon={<img src={selectDropdownIcon} alt='' />} disabled={
                !subjectByCourse || subjectByCourse.length === 0
              }>
                {subjectByCourse?.map((subject) => (
                  <Option key={subject._id} value={subject._id}>
                    {subject.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
            name="chapter">
           
          <Select placeholder="Select a chapter" style={{ width: '100%'}} suffixIcon={<img src={selectDropdownIcon} alt='' />} disabled={
            !chapterBySubject || chapterBySubject.length === 0
          }>
            {chapterBySubject?.map((chapter) => (
              <Option key={chapter._id} value={chapter._id}>
                {chapter.name}
              </Option>
            ))}
          </Select>
            </Form.Item>
              </>)
            }
            <Form.Item
              name="details"
              rules={[{ required: true, message: 'Please enter the question details' }]}
            >
              <TextArea rows={4} placeholder="Description..." />
            </Form.Item>
            <Form.Item
              name="file"
              valuePropName="fileList"
              >
              <FileUploader files={files} setFiles={setFiles}/>
              </Form.Item>

            </Form>
            <Form.Item style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '0',
              marginTop: '48px',
            }}
             
            >
              <Button type="primary" danger 
              className="modal-submit-btn"
              onClick={submit}
              >Submit</Button>
            </Form.Item>
          </Content>
      </Layout>
      </Modal>
    </div>
   

   
  );
}

export default StudentQuestionForm;
